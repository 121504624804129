<script setup>
import { useForm } from '@inertiajs/vue3';
import { useDebounceFn } from '@vueuse/core';
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';

// Components
import Pagination from '@/Components/Pagination/PaginationFE.vue';
import Filters from '@/Components/Table/Filters.vue';
import Table from '@/Components/Table/Table.vue';

// Layouts
import LocationLayout from '@/Layouts/LocationLayout.vue';

// Partials

// Variables
const { t } = useI18n();
const route = inject('route');
const props = defineProps({
    reviews: Object,
    query: Object,
    flexers: Object,
    liked_flexer_ids: Object,
});

const typeFilters = {
    rating: 'Rating',
    favorite: 'Favorite',
};

const form = useForm({
    search: props.query.search ?? '',
    orderBy: props.query.orderBy ?? '',
    orderDir: props.query.orderDir ?? 'desc',
    minimum_rating: props.query.minimum_rating ?? 0,
    favorite: props.query.favorite ?? 'all',
    page: props.query.page ?? 1,
});

const getData = (resetPage = true) => {
    form.page = resetPage ? 1 : form.page;

    form.get(route('location.flexers.index'), {
        preserveState: true,
        only: ['flexers', 'query'],
    });
};

const debouncedGetData = useDebounceFn(() => getData(), 300);

const tableSettings = [
    { label: t('Name'), cell: 'FlexerCard', orderBy: 'name', width: '30%' },
    { label: t('Email'), cell: 'Email', width: '20%' },
    { label: t('Phone'), width: '18%', cell: 'Tel' },
    { label: t('Review'), orderBy: 'rating', cell: 'Rating', width: '20%' },
    { label: t('Shifts'), orderBy: 'completed_shift_count', cell: 'Bold', width: '10%' },
    { label: '', cell: 'Arrow', width: '2%' },
];

const tableData = computed(() =>
    props.flexers.data.map((flexer) => {
        return {
            id: flexer.id,
            rowData: [
                // Whatever it is it will be passed as cellData in the tBody
                { ...flexer, is_liked: props.liked_flexer_ids.includes(flexer.id) },
                flexer.email,
                flexer.phone_number,
                [flexer.received_reviews_avg_rating, flexer.received_reviews_count],
                flexer.completed_shift_count,
                route('location.flexers.show', flexer.id),
            ],
        };
    })
);

const setPage = (e) => {
    form.page = e;
    getData(false);
};

const cellClicked = ({ row, column, id }) => console.table(row, column, id);
</script>

<template>
    <LocationLayout :title="$t(`Fl@xr's`)">
        <Filters
            :form="form"
            @anyUpdate="getData()"
            @searchUpdate="debouncedGetData()"
            :h1="$t(`Fl@xr's`)"
            :data="flexers"
            :hasSearch="true"
            :typeFilters="typeFilters"
        />

        <template v-if="typeof flexers !== 'undefined' && flexers.data.length > 0">
            <Table
                class="mb-8"
                :loading="form.processing"
                :tableSettings="tableSettings"
                :data="tableData"
                v-model:orderBy="form.orderBy"
                @update:orderBy="getData()"
                v-model:orderDir="form.orderDir"
                @update:orderDir="getData()"
                @cellClicked="cellClicked"
            />
            <pagination
                v-if="typeof flexers !== 'undefined'"
                :links="flexers.links"
                @setPage="setPage"
                :pageData="flexers"
                :hideTotal="query.search != '' && query.search != null"
            />
        </template>
        <template v-else>
            <div class="w-full py-3 overflow-hidden text-base leading-tight bg-white rounded min-h-20 text-blue">
                {{ $t('No {model} found', { model: $t(`Fl@xr's`) }) }}
            </div>
        </template>
    </LocationLayout>
</template>
